import React, {useState} from "react";
//import { Link } from "gatsby";
import Arrow from "../../images/drop_up_grey.svg";



const ParagraphFaq = ({ data, context, ...props }) => {

    const {title, relationships } = data
    const [selected, setSelected] = useState(-1);
    const {className} = props

    if ( context === 'v2') {

      const items = relationships.questions.map((item, index) => (
        <div className="border-b" key={index}>
          <div className="flex flex-row justify-between items-top lg:px-4 py-3">
            <div className="w-full space-y-3" >
              <button
                className="w-full text-left  text-xl font-light  h-10" 
                onClick={() => setSelected(selected === index ? -1 : index)}
              >
                {item.question}
              </button>
              {selected === index && <div className="font-light ">{item.answer}</div>}
            </div>
    
            <div className="">
              <button
                onClick={() => setSelected(selected === index ? -1 : index)}
                className="flex flex-row justify-around items-center w-10 h-10  "
              >
                <img
                  src={Arrow}
                  className={
                    "transition " + (selected === index ? " rotate-180" : " ")
                  }
                  alt="arrow"
                />
              </button>
            </div>
          </div>
        </div>
      ));
  
  
      return (
        <section className="">
          <div className="container py-12 px-0 lg:p-[50px] lg:mx-4">
            <h2 className="text-[3rem] font-medium mb-6">{title}</h2> 
            {/* <div className="text-center my-6" dangerouslySetInnerHTML={{__html:description.processed}} /> */}
            <div>{items}</div>
          </div>
        </section>
      );
    
    }


    if ( context === 'v3') {

      const items = relationships.questions.map((item, index) => (
        <div className="border-b" key={index}>
          <div className="flex flex-row justify-between items-top py-6 text-[#3D4A5C] text-lg font-regular">
            <div className="w-full space-y-6">
              <button
                className="w-full text-left   " 
                onClick={() => setSelected(selected === index ? -1 : index)}
              >
                {item.question}
              </button>
              {selected === index && <div className="">{item.answer}</div>}
            </div>
    
            <div className="">
              <button
                onClick={() => setSelected(selected === index ? -1 : index)}
                className="flex flex-row justify-around items-center w-10 h-10  "
              >
                <img
                  src={Arrow}
                  className={
                    "transition " + (selected === index ? " rotate-180" : " ")
                  }
                  alt="arrow"
                />
              </button>
            </div>
          </div>
        </div>
      ));
  
  
      return (
        // <section className="py-12 lg:py-24 mx-4">
          <div className={className}>
            <h2 className=" text-[24px] font-semibold  pb-2 pt-4 text-[#3D4A5C] leading-[40px] ">{title}</h2>
            
            {/* <div className="text-center my-6" dangerouslySetInnerHTML={{__html:description.processed}} /> */}
            <div>{items}</div>
          </div>
        // </section>
      );
    
    }


    const items = relationships.questions.map((item, index) => (
      <div className="border-b" key={index}>
        <div className="flex flex-row justify-between items-top px-4 py-6">
          <div className="w-full space-y-6">
            <button
              className="w-full text-left  text-xl " 
              onClick={() => setSelected(selected === index ? -1 : index)}
            >
              {item.question}
            </button>
            {selected === index && <div className="">{item.answer}</div>}
          </div>
  
          <div className="">
            <button
              onClick={() => setSelected(selected === index ? -1 : index)}
              className="flex flex-row justify-around items-center w-10 h-10  "
            >
              <img
                src={Arrow}
                className={
                  "transition " + (selected === index ? " rotate-180" : " ")
                }
                alt="arrow"
              />
            </button>
          </div>
        </div>
      </div>
    ));


    return (
      <section className="py-12 lg:py-24 mx-4">
        <div className="container max-w-[940px] px-0 ">
          <h2 className=" text-4xl font-semibold mb-6">{title}</h2>
          
          {/* <div className="text-center my-6" dangerouslySetInnerHTML={{__html:description.processed}} /> */}
          <div>{items}</div>
        </div>
      </section>
    );
  
};


export default ParagraphFaq;
