import React from "react";
import { Link } from "gatsby";
import { StructuredData } from "../Seo";

const BreadCrumbs = ({ tip, base, title }) => {
  const hpUrl = base + "/";

  const jsonLD = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [],
  };

  jsonLD.itemListElement.push({
    "@type": "ListItem",
    position: 1,
    name: "Home",
    item: base + "/",
  });

  jsonLD.itemListElement.push({
    "@type": "ListItem",
    position: 2,
    name: "Resources",
    item: base + "/resources/",
  });

  jsonLD.itemListElement.push({
    "@type": "ListItem",
    position: 3,
    name: title,
  });

  return (
    <>
      <section className="pb-6 text-[#2D3643] ">
        <div className="flex flex-row flex-wrap text-sm space-x-4">
          <a href={hpUrl}>Home</a>
          <span>{" / "}</span>
          <Link to={"/resources/"}>Resources</Link>
          <span>{" / "}</span>
        </div>
      </section>
      <StructuredData>{[{ ...jsonLD }]}</StructuredData>
    </>
  );
};


export default BreadCrumbs;
